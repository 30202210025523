<template>
  <v-row justify="center">
    <v-col
      cols="12"
      sm="8"
      lg="6"
    >
      <page-title
        title="Sign-In"
        subtitle="Login or create an account to save airfields to your list, keep track of airfields visited and rate your visit"
      />
      <validation-observer ref="observer">
        <v-form
          ref="form"
          lazy-validation
          :disabled="isSubmitting"
          @submit.prevent="signIn"
        >
          <v-card>
            <v-card-text>
              <p
                v-if="isMagicLinkSent"
                class="text-body-1"
              >
                We've sent you a magic link to <b>{{ email }}</b>, please click the link in your email to complete your sign-in.
              </p>
              <p
                v-if="!isMagicLinkSent"
                class="text-body-1"
              >
                Enter your email and we'll send you a magic link to login.
              </p>
              <validation-provider
                v-if="!isMagicLinkSent"
                v-slot="{ errors }"
                name="email"
                rules="required|email"
              >
                <v-text-field
                  label="Email Address"
                  type="email"
                  autocomplete="email"
                  required
                  v-model="email"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="!isMagicLinkSent"
                color="primary"
                type="submit"
                class="mb-4"
                :loading="isSubmitting"
              >
                Sign In
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

export default {
  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Please enter an email address",
    });

    extend("email", {
      ...email,
      message: "Email must be valid",
    });
  },

  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    isSubmitting: false,
    isMagicLinkSent: false,
    email: "",
  }),

  methods: {
    signIn() {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async (isValid) => {
          if (isValid) {
            try {
              const { error } = await this.$supabase.auth.signIn({
                email: this.email,
              });
              if (error) throw error;

              this.isMagicLinkSent = true;
            } catch (error) {
              alert(error.error_description || error.message);
            } finally {
              this.isSubmitting = false;
            }
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
